
let AdminPageContentInstance = (function () {
    let instance;

    function createInstance(di, autoScrollManager) {
        let object = new AdminPageContentManager(di, autoScrollManager);
        return object;
    }

    return {
        getInstance: function (di, autoScrollManager) {
            if (!instance) {
                instance = createInstance(di, autoScrollManager);
            }

            return instance;
        }
    };
})();

function AdminPageContentManager(di, autoScrollManager) {
    this.colWrapperClass = '.meeb__moset-col-wrapper';
    this.bodyOverlayClass = 'page-overlay';
    this.bodyHideLinksClass = 'meeb__admin-hide-links';

    this.di = di;
    this.autoScrollManager = autoScrollManager;

    this.page = di.getService('page');
    this.channel = null;
}

AdminPageContentManager.prototype.toggleOverlay = function() {
    const visible = $('body').hasClass(this.bodyOverlayClass);
    if (visible) {
        this.hideOverlay();
    } else {
        this.showOverlay();
    }
}

AdminPageContentManager.prototype.showOverlay = function() {
    const body = $('body');
    body.addClass(this.bodyOverlayClass);
    body.addClass(this.bodyHideLinksClass);

    this.initHandles();
}

AdminPageContentManager.prototype.hideOverlay = function () {
    const body = $('body');
    body.removeClass(this.bodyOverlayClass);
    body.removeClass(this.bodyHideLinksClass);

    $(document).off('.page-content-handles');
}

AdminPageContentManager.prototype.initHandles = function() {
    const me = this;

    $(document).off('.page-content-handles');
    $(document).on('click.page-content-handles', this.colWrapperClass, function (e) {
        e.preventDefault();
        e.stopPropagation();

        const self = $(this);
        const colId = self.data('col-id');
        const rowId = self.data('row-id');

        const target = $('#col-manager-form');
        target.removeClass('d-none');
        const position = self.offset();
        const sizes = { x: self.width(), y: self.height()}

        const targetPosX = Math.min(position.left + sizes.x, $(window).width() - target.width() - 50);
        const targetPosY = Math.max(position.top - 50, 50);

        target.css({ left: targetPosX, top: targetPosY });

        $('body').addClass('show-overlay');
        self.closest('.row').addClass('meeb__moset-highlight')

        MosetInstance.getInstance().collapse();
        me.setupFormArea(target, self, colId, rowId);

        target.find('.nav-tabs li').click(function() {
            target.find('.nav-tabs li.active').removeClass('active');
            $(this).addClass('active');
        });

        const link = target.data('load-defaults');
        target.find(':input').prop('disabled', true);

        $.post(link, { colId: colId, rowId: rowId }, function(result) {
            target.find(':input').prop('disabled', false);
            if (!result.success) return;

            const formRow = target.find('.form-edit-row');
            const formCol = target.find('.form-edit-col');

            Object.entries(result.row).forEach(item => formRow.find(':input[name="' + item[0] + '"]').val(item[1]));
            Object.entries(result.col).forEach(item => formCol.find(':input[name="' + item[0] + '"]').val(item[1]));
        });

        const contentLink = target.data('content-link');

        $('#content-tab').addClass('disabled');
        $.post(contentLink, { colId: colId }, function (result) {
            if (!result.success) return;

            $('#content-frame').attr('src', result.link);
            $('#content-tab').removeClass('disabled');
        });

        if (!me.channel) {
            me.channel = new BroadcastChannel('iframe:channel');
            me.channel.onmessage = function(event) {
                if (event.data.success) {
                    const link = $('#meeb__close-button').data('link');

                    me.autoScrollManager.disableNextScroll();
                    me.page.open(link, 'POST', null, { history: false, transition: false }).then(function() {
                        $('div[data-col-id="' + colId + '"]').closest('.row').addClass('meeb__moset-highlight');
                    });
                }
            }
        }

        /*
        const link = $(this).data('edit-link');
        //MeebsterIframeInstance.getInstance().show(link);
        window.open(link);
         */
    });
}

AdminPageContentManager.prototype.setupFormArea = function(form, target, colId, rowId) {
    const self = this;

    const type = form.find('input[name="type"]').val();
    form.find('input[name="id"]').val(type === 'row' ? rowId : colId);

    form.off('.col-edit-form');
    form.on('focus.col-edit-form', '.form-control', function() {
        form.addClass('transparent');
        self.processFocusedClasses($(this), $(target), false);
    });
    form.on('blur.col-edit-form', '.form-control', function() {
        form.removeClass('transparent');
        self.processFocusedClasses($(this), $(target), true);

        self.synchronizeClasses($(this).closest('form'), $(target));
    });
    form.on('change.col-edit-form', '.form-control', function () {
        $(this).blur();
    });

    form.on('click.col-edit-form', '.btn-submit', function(e) {
        e.stopPropagation();
        e.preventDefault();

        self.submitForm(form);
    });

    form.on('click.col-edit-form', '.btn-reset', function(e) {
        e.stopPropagation();
        e.preventDefault();

        self.resetForm(form, $(target), colId, rowId);
    });

    form.on('click.col-edit-form', '.close-button', function(e) {
        e.stopPropagation();
        e.preventDefault();

        form.find(':input').prop('disabled', true);
        const link = $(this).data('link');

        self.autoScrollManager.disableNextScroll();
        self.page.open(link, 'POST', null, { history: false, transition: false }).then(function() {
            const target = $('#col-manager-form');
            target.addClass('d-none');

            self.hideOverlay();
            form.find(':input').prop('disabled', false);
            $('body').removeClass('show-overlay');

            const frame = document.getElementById("content-frame"), frameDoc = frame.contentDocument || frame.contentWindow.document;
            frameDoc.removeChild(frameDoc.documentElement);

            if (self.channel) {
                self.channel.close();
                self.channel = null;
            }
        });
    });

    form.on('click.col-edit-form', '.nav.nav-tabs li.active a', function(e) {
        e.stopPropagation();
        e.preventDefault();

        const target = $(this).attr('href');
        const wrapper = $(this).closest('.col-edit-form');

        wrapper.find('li.active').removeClass('active');
        $(this).closest('li').addClass('active');

        wrapper.find('.tab-content .tab-pane.active').removeClass('active').removeClass('show');
        $(target).addClass('active show');
    });
}

AdminPageContentManager.prototype.resetForm = function(formWrapper, target, colId, rowId) {
    const link = formWrapper.data('reset');
    formWrapper.find(':input').prop('disabled', true);

    $.post(link, { colId: colId, rowId: rowId }, function(result) {
        formWrapper.find(':input').prop('disabled', false);
        if (!result.success) return;

        const formRow = formWrapper.find('.form-edit-row');
        const formCol = formWrapper.find('.form-edit-col');

        Object.entries(result.col.data).forEach(item => formCol.find(':input[name="' + item[0] + '"]').val(item[1]));
        Object.entries(result.row.data).forEach(item => formRow.find(':input[name="' + item[0] + '"]').val(item[1]));

        target.attr('class', result.col.clazz);
        target.closest('.row').attr('class', result.row.clazz);
    });
}

AdminPageContentManager.prototype.submitForm = function(formWrapper) {
    const forms = formWrapper.find('form');
    const data = [];

    const self = this;
    forms.each(function() {
        data.push(self.getFormValues($(this)));
    });

    const link = formWrapper.data('submit');

    formWrapper.find(':input').prop('disabled', true);
    this.autoScrollManager.disableNextScroll();
    this.page.open(link, 'POST', data, { history: false, transition: false }).then(function() {
        formWrapper.find(':input').prop('disabled', false);
    });
}

AdminPageContentManager.prototype.processFocusedClasses = function(control, target, hide) {
    const clazz = control.data('class-toggle');
    const type = control.closest('form').find('input[name="type"]').val();

    const elem = type === 'row' ? target.closest('.meeb__moset-highlight') : target;
    if (hide) {
        elem.removeClass(clazz);
    } else {
        elem.addClass(clazz);
    }
}

AdminPageContentManager.prototype.getFormValues = function(form) {
    let serialized = form.serializeArray();
    serialized = serialized.filter(item => item.name !== '_do');

    let data = {};
    serialized.forEach(item => data[item.name] = item.value);

    return data;
}

AdminPageContentManager.prototype.synchronizeClasses = function(form, target) {
    const data = this.getFormValues(form);

    const link = form.closest('.col-edit-form').data('process-classes');
    const type = data['type'];

    const elem = type === 'row' ? target.closest('.row') : target;

    $.post(link, {values: data}, function (result) {
        if (result.success) {
            elem.attr('class', result.generated_class);
        }
    });
}