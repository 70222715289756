// Disable console
/*var console = {};
console.log = function(){};
window.console = console;
*/

const sendError = async (evt) => {
    //TODO do budoucna poresit cross domain request - automaton bude na jinem serveru pravdepodobne
    const endpoint = `https://automaton.meebio.net/logs/`;
    try{
        console.log('sending log to automaton');
        const response = await fetch(endpoint, {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify(typeof evt.data !== 'undefined' ? evt.data : evt),
        });

        if(response.ok){
            return await response.json()
        }

        return null;
    }
    catch(error){
        console.log('sending failed');
        console.log(error)
    }
}

window._stack.push(function(di) {
    window._forceClean = false;
    di.getService('page').on('error', function (evt) {

        if (evt && typeof evt.data !== 'undefined') {
            console.error(evt.data);
        } else {
            console.error(evt);
        }

        if (evt && typeof evt.data !== 'undefined' && typeof evt.data.type !== "undefined" && evt.data.type === 'abort') {
            window._forceClean = true;
            return false;
        }

        //TODO uncomment az budeme ready sendError(evt).then(r => console.log(r));
        const message = 'Ups, tak tady se nám něco pokazilo, nemám raději <a href="#" onclick="window.location.reload()">obnovit</a> stránku?';
        FlashMessageManager.getInstance(di).addMessage(message, 'error');

        return false;
    });

    di.getService('snippetManager').on('after-update', function() {
        if (window._forceClean) {
            setTimeout(function() {
                const transition = $('.nittro-transition-auto');

                transition.removeClass('nittro-transition-active');
                transition.removeClass('nittro-transition-middle');

                window._forceClean = false;
            }, 250);
        }
    });

    let message = null;
    let timer = null;
    window.addEventListener('offline', function(e) {
        timer = setTimeout(function() {
            const messageText = 'Hmmm, už se nějakou chvilku nedokážu připojit k internetu. Máme signál?';
            const res = FlashMessageManager.getInstance(di).addMessage(messageText, 'error');
            if (res) message = res;
            timer = null;
        }, 5000);
    });
    window.addEventListener('online', function(e) {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        if (message)  {
            FlashMessageManager.getInstance(di).removeMessage(message);
            message = null;
        }
    });
});


String.prototype.parseFromLocalizedNumber = String.prototype.parseFromLocalizedNumber ||
    function() {
        "use strict";
        const regex = /[^\d.]/;
        const parsed = this.replace(',', '.').replace(regex, '');

        return parseFloat(parsed);
    }

String.prototype.isValidFloat = String.prototype.isValidFloat ||
    function() {
        "use strict";
        const regex = /^[+-]?([0-9]+(([.]|[,])[0-9]*)?)$/;

        return this.match(regex);
    }

String.prototype.isValidInt = String.prototype.isValidInt ||
    function() {
        "use strict";
        const parsed = parseInt(this);
        return this == parsed && !isNaN(parsed);
    }

String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
    function () {
        "use strict";
        var str = this.toString();
        if (arguments.length) {
            var t = typeof arguments[0];
            var key;
            var args = ("string" === t || "number" === t) ?
                Array.prototype.slice.call(arguments)
                : arguments[0];

            for (key in args) {
                str = str.replace(new RegExp("%" + key + "%", "gi"), args[key]);
            }
        }

        return str;
    };

jQuery.fn.outerHTML = function() {
    return (this[0]) ? this[0].outerHTML : '';
};

if (typeof $.fn.dataTable !== 'undefined') {
    $.fn.dataTable.moment = function ( format, locale ) {
        var types = $.fn.dataTable.ext.type;

        // Add type detection
        types.detect.unshift( function ( d ) {
            return moment( d, format, locale, true ).isValid() ?
                'moment-'+format :
                null;
        } );

        // Add sorting method - use an integer for the sorting
        types.order[ 'moment-'+format+'-pre' ] = function ( d ) {
            return moment( d, format, locale, true ).unix();
        };
    };

    $.fn.dataTable.moment('D.M.YYYY HH:mm')

    // Custom filtering
    $.fn.dataTable.ext.search.unshift(
        function( settings, data, dataIndex ) {
            var result = true;
            var table = $(settings['nTable']);
            for (var key in data) {
                // If there is checkbox all our cols are moved by one
                var target = data[key];
                var filters = table.find('.filters th[data-col="' + key +'"] .datatable-filter');

                filters.each(function() {
                    var filter = $(this);
                    var val = filter.val();
                    var type = filter.data('filter-type');

                    if (result === false)
                        return;

                    if (type === 'text' || type === 'select') {
                        if (target !== null && val !== null) {
                            result = target.toLowerCase().search(val.toLowerCase()) !== -1;
                        }
                    } else if (type === 'date-since' || type === 'date-until') {
                        var format = filter.data('format');
                        var date = moment(val, format, true);
                        var time = moment(target, format, true);

                        if (date.isValid()) {
                            if (type === 'date-since') {
                                result = time >= date;
                            } else {
                                result = time <= date;
                            }
                        }
                    } else if (type === 'price-min' || type === 'price-max') {
                        var valPrice = parseFloat(val);
                        if (!Number.isNaN(valPrice)) {
                            var priceMin = 0;
                            var priceMax = 0;
                            var targetPrice = target.replace(',', '.');
                            var split = targetPrice.split('-');
                            if (split.length > 1) {
                                priceMin = parseFloat(split[0]);
                                priceMax = parseFloat(split[1]);
                            } else {
                                priceMin = parseFloat(targetPrice);
                                priceMax = parseFloat(targetPrice);
                            }

                            if (type === 'price-min') {
                                result = priceMin >= valPrice;
                            } else {
                                result = priceMax <= valPrice;
                            }
                        }
                    }
                });

                if (result === false)
                    break;
            }

            return result;
        }
    );
}

function select(selector, target) {
    if (typeof target === "undefined") {
        return $(selector);
    }

    if (target instanceof jQuery) {
        return target.find(selector);
    }

    return $('#' + target).find(selector);
}

function initBootstrapTooltip() {
    $('[data-toggle="tooltip"]').tooltip({
        container: 'body',
    });
}

function checkCompanyIdentificationNumber(number) {

    if(number.length == 0)
        return true;
    if(number.length != 8)
        return false;

    var sum = 0;
    var digits = number.split('');
    for(var i = 0; i < 7; i++) {
        sum += (parseInt(digits[i]) * (8 - i));
    }

    sum = sum % 11;
    var control = 11 - sum;

    if(sum == 1)
        control = 0;
    if(sum == 0)
        control = 1;
    if(sum == 10)
        control = 1;

    if(parseInt(digits[7]) != control)
        return false;

    return true;
}

function addOptionsToSelect($select, options, clear)
{
    if (typeof clear === 'undefined')
        clear = true;

    if (clear)
        $select.empty();

    var firstKey = false;
    for (var key in options) {
        if (firstKey === false)
            firstKey = key;

        $select.append($('<option>', { value: key, text: options[key] }));
    }

    $select.val(firstKey);
    $select.trigger('change');
    ///$select.children().first().attr('selected', true);
}

var loadingOverlayManager = null;
function showLoadingOverlay() {
    getLoadingOverlayManager().show();
}

function hideLoadingOverlay() {
    getLoadingOverlayManager().hide();
}

function getLoadingOverlayManager() {
    if (loadingOverlayManager) {
        return loadingOverlayManager;
    }

    loadingOverlayManager = new MeebLoadingOverlay();
    return loadingOverlayManager;
}

function initializeCrud(di, target) {
    dataTablesInit(target);
    initPrettySelect(target);
    initIChecks(target);
    initSummernote(target);
    initDatePicker(target);
    initVisibilityDependentFields(target);
    initFallbackFields(target);
    initColorPicker(target);
    initTableCrudImage(target);
    initCopyableFields(target);

    new AutoGenerateManager(di, target);
}

function initCopyableFields(target) {
    let element = $(document);
    if (typeof target !== "undefined") {
        element = $('#' + target);
    }

    element.off('.copyable');
    element.on('click.copyable', '*[data-copyable]', function(e) {
        e.preventDefault();
        e.stopPropagation();


        let text = null;
        const attr = $(this).attr('title');
        if (typeof attr !== 'undefined' && attr !== false) {
            text = attr;
        } else {
            text = $(this).text();
        }

        console.log(text);
        const copyContent = async () => {
            try {
                await navigator.clipboard.writeText(text);
                console.log('Content copied to clipboard');
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        }

        copyContent();
    });
}

function initTableCrudImage(target) {
    let element = $(document);
    if (typeof target !== "undefined") {
        element = $('#' + target);
    }

    element.off('.tcimg');
    element.on('click.tcimg', '.table-crud-image', function(e) {
        e.preventDefault();
        e.stopPropagation();

        let src = $(this).data('image');
        let body = $('body');
        body.addClass('image-view-overlay-open');
        body.append($('<div class="image-view-overlay"><div class="content"><img src="' + src + '"></div></div>'));
        body.on('click.tcimg-wrapper', function() {
            body.removeClass('image-view-overlay-open');
            body.off('.tcimg-wrapper');
            $('.image-view-overlay').remove();
        })
    });
}


function initColorPicker(target) {
    const selector = '.color-picker';
    select(selector, target).each(function() {
        //TODO pridat bootstrap color
        let colors = $(this).data('default-colors');
        const parent = $(this).parent();
        const input = $(this);

        parent.addClass('position-relative color-picker');

        const colorPicker = new iro.ColorPicker(parent[0], {
                width: 350,
                color: input.val(),
            });

        parent.append('<div class="color-display"></div>');
        const display = parent.find('.color-display');
        display.click(function() {
            input.focus();
        });

        colorPicker.on(["color:init", "color:change"], function(color) {
            input.val(color.hexString);
            display.attr('style', "background: " + color.hexString);
        });

        input.change(function() {
            colorPicker.color.hexString = $(this).val();
            display.attr('style', "background: " + $(this).val());
        });

    });
}

function addRevertButton($element) {
    var revertButton = $('<a href="#" class="fallback-icon btn btn-danger btn-xs p-0"><i class="fa fa-undo"></i></a>');

    $element.closest('.from-group').css('display', 'relative');
    var closest = $element.closest('div');
    closest.append(revertButton);

    closest.find('.fallback-icon').click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        $element.val($element.data('fallback'));
        $element.attr('readonly', true);

        revertButton.remove();
    });

    return revertButton;
}

function initFallbackField($element) {
    var fallback = $element.data('fallback');
    var val = $element.val();
    if ($element.attr('readonly')) {
        return false;
    }

    var revertButton = false;
    if (val === fallback) {
        $element.attr('readonly', true);
        $element.click(function() {
            $(this).attr('readonly', false);
            $(this).focus();

            revertButton = addRevertButton($element);
        });

        $element.blur(function() {
            if ($element.data('fallback') === $element.val()) {
                $element.attr('readonly', true);

                if (revertButton) {
                    revertButton.remove();
                    revertButton = false;
                }
            }
        });
    } else {
        revertButton = addRevertButton($element);
    }
}

function addSummernoteRevertButton($element, noteEditingArea) {
    var revertButton = $('<a href="#" class="fallback-icon btn btn-danger btn-xs"><i class="fa fa-undo"></i></a>');

    noteEditingArea.css('position', 'relative');
    noteEditingArea.append(revertButton);

    noteEditingArea.find('.fallback-icon').click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        $element.summernote('code', $element.data('fallback'));
        $element.summernote('disable');
        $(this).remove();
    });

    return revertButton;
}

function initFallbackFields(target) {
    const inputSelector = 'input[data-fallback]';
    const textAreaSelector = 'textarea[data-fallback]';

    select(inputSelector, target).each(function() {
        let type = $(this).attr('type');
        if (type != 'hidden' && type != 'checkbox') {
            initFallbackField($(this));
        }
    });

    select(textAreaSelector, target).each(function() {
        let $this = $(this);
        let revertButtonTemplate = $('<a href="#" class="fallback-icon btn btn-danger btn-xs"><i class="fa fa-undo"></i></a>');

        if ($this.hasClass('wysiwyg-editor')) {
            if ($this.val() === $this.data('fallback')) {
                $this.summernote('disable');

                let noteEditingArea = $this.closest('div').find('.note-editing-area');
                noteEditingArea.click(function () {
                    $this.summernote('enable');
                    $this.summernote('focus');

                    addSummernoteRevertButton($this, noteEditingArea);
                });

                $this.on('summernote.blur', function () {
                    if ($this.val() == $this.data('fallback')) {
                        $this.summernote('disable');

                        if (revertButtonTemplate) {
                            revertButtonTemplate.remove();
                        }
                    }
                });
            } else {
                addSummernoteRevertButton($this, $this.closest('div').find('.note-editing-area'));
            }
        } else {
            initFallbackField($this);
        }
    });
}

function initNestedCheckbox() {
    $('.nested-checkbox input[type="checkbox"]').on('change', function () {
        var $this = $(this);

        var checked = $this.is(':checked');
        //var checked = typeof $this.attr('checked') !== "undefined" && $this.attr('checked') !== false;
        $this.closest('li').find('input[type="checkbox"]').each(function() {
            $(this).prop('checked', checked);
        });

        /*
        var parent = $this.closest('input[type="checkbox"]');
        if (parent.length > 0) {
            var checkedCount = $this.find('input[type="checkbox"]:checked').length;
            var totalCount = $this.find('input[type="checkbox"]').length;

            if (checkedCount === 0) {
                parent.prop('indeterminate', false);
                parent.
            }
        }*/
    });
}

function initCheckboxSearch(target, parent) {
    $(target).on('keyup.filter', function () {
        var search = $(this).val().toLowerCase();

        $(this).closest(parent).find('input[type="checkbox"]').each(function () {
            var elem = $(this);
            var target = elem.closest('label');

            var text = target.text().toLowerCase();
            if (text.indexOf(search) != -1) {
                target.show();
                target.next().show();
            } else {
                target.hide();
                target.next().hide();
            }
        });
    });
}

function destroyCheckboxSearch(target) {
    $(target).off('.filter');
}

function initLightboxGallery() {
    $('.lightBoxGallery').click(function (e) {
        var target = e.target;
        if ($(target).hasClass('lightbox-img')) {
            var link = target.src ? target.parentNode : target;
            var options = {index: link, event: event};
            var links = $(this).find('.lightbox-img');

            blueimp.Gallery(links, options);
        }
    });
}

function initSlimScroll() {
    /*$('.full-height-scroll').slimscroll({
        height: '100%'
    });*/
}

function initDatePickerElement(elem, clearButton) {
    const $this = elem;
    const format = $this.data('format');
    const locale = $this.data('locale');

    const dateTime = $this.datetimepicker({
        format: format,
        locale: locale,
        showTodayButton: true,
        showClear: clearButton,
        //debug: true,
    });

    dateTime.on('dp.show', function() {
        const widget = $('.bootstrap-datetimepicker-widget');
        widget.find('li.collapse.in').addClass('show');
        widget.on('click', 'a[data-action="togglePicker"]', function(e) {
            widget.find('li.collapse').toggleClass('show');
        });
    });

    return dateTime;
}

function initDatePicker(target) {
    const selector = '.date-input';

    select(selector, target).each(function () {
        initDatePickerElement($(this), false);
    });
};

function destroyDatePicker(target) {
    const selector = '.date-input';

    select(selector, target).each(function () {
        let $this = $(this);
        if (typeof $this.datetimepicker() === 'function') {
            $this.datetimepicker('destroy');
        }
    });
};

function updateSnippets(data) {
    if (typeof data.snippets !== undefined) {
        for (var key in data.snippets) {
            $('#' + key).html(data.snippets[key]);
        }
    }

    if (typeof data.flashes !== "undefined") {
        for (var key in data.flashes) {
            if (data.flashes && data.flashes[key] !== null) {
                data.flashes[key].forEach(function(message) {
                    toastr[message.type](message.message);
                });
            }
        }
    }
}

function supports_html5_storage() {
    try {
        return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
        return false;
    }
}

function getCheckedIdsFromCrudTable(target) {
    let ids = [];
    $(target).find('.table-check:checked').each(function() {
        const id = $(this).data('row-id');
        if (typeof id !== "undefined") {
            ids.push(id);
        }
    });

    return ids;
}

function getDataTablesLanguages() {
    return {
        cs_CZ: {
            "sEmptyTable":     "Tabulka neobsahuje žádná data",
            "sInfo":           "Zobrazuji _START_ až _END_ z celkem _TOTAL_ záznamů",
            "sInfoEmpty":      "Zobrazuji 0 až 0 z 0 záznamů",
            "sInfoFiltered":   "(filtrováno z celkem _MAX_ záznamů)",
            "sInfoPostFix":    "",
            "sInfoThousands":  " ",
            "sLengthMenu":     "Zobraz záznamů _MENU_",
            "sLoadingRecords": "Načítám...",
            "sProcessing":     "Provádím...",
            "sSearch":         "Hledat:",
            "sZeroRecords":    "Žádné záznamy nebyly nalezeny",
            "oPaginate": {
                "sFirst":    "První",
                "sLast":     "Poslední",
                "sNext":     "Další",
                "sPrevious": "Předchozí"
            },
            "oAria": {
                "sSortAscending":  ": aktivujte pro řazení sloupce vzestupně",
                "sSortDescending": ": aktivujte pro řazení sloupce sestupně"
            }
        },
        en_US: {
            "sEmptyTable":     "No data available in table",
            "sInfo":           "Showing _START_ to _END_ of _TOTAL_ entries",
            "sInfoEmpty":      "Showing 0 to 0 of 0 entries",
            "sInfoFiltered":   "(filtered from _MAX_ total entries)",
            "sInfoPostFix":    "",
            "sInfoThousands":  ",",
            "sLengthMenu":     "Show _MENU_ entries",
            "sLoadingRecords": "Loading...",
            "sProcessing":     "Processing...",
            "sSearch":         "Search:",
            "sZeroRecords":    "No matching records found",
            "oPaginate": {
                "sFirst":    "First",
                "sLast":     "Last",
                "sNext":     "Next",
                "sPrevious": "Previous"
            },
            "oAria": {
                "sSortAscending":  ": activate to sort column ascending",
                "sSortDescending": ": activate to sort column descending"
            }
        }
    };
}


function dataTablesInit(target) {
    const selector = '.dataTables';

    const langs = getDataTablesLanguages();
    select(selector, target).each(function () {

        let columnCount = $(this).find('thead tr:not(.filters) th').length - 1;
        if ( $.fn.dataTable.isDataTable(this) ) {
            $(this).DataTable();
            $(this).trigger('meebtables:init');
        } else {
            const $this = $(this);
            const orderColumn = $this.data('sort-column');
            const orderDirection = $this.data('sort-order');
            const langCode = $this.data('lang-code');
            const lang = langs[langCode];
            const emptyTableMessage = $this.data('empty-table-message');

            if (typeof emptyTableMessage !== 'undefined') {
                lang.sEmptyTable = emptyTableMessage;
            }

            let columnDefs = [
                { "orderable": false, "targets": columnCount }
            ];

            if ($this.data('checkbox-select')) {
                columnDefs = [
                    { "targets": 0, "checkboxes": { 'selectRow': true }},
                ];
            }

            const nonSortableColumns = $this.data('non-sortable-col');
            if (nonSortableColumns) {
                nonSortableColumns.forEach((id) => columnDefs.push({"targets": id, "orderable": false}));
            }

            var options = {
                "language": lang,
                "destroy": true,
                "pageLength": 25,
                "processing": true,
                "columnDefs": columnDefs,
                'select': {
                    'style': 'multi'
                },
                "rowCallback": function (row, data, dataIndex, cells) {
                    if (typeof data['DT_RowStyle'] !== "undefined") {
                        $(row).attr('style', data['DT_RowStyle']);
                    }
                    if (typeof data['DT_DataId'] !== "undefined") {
                        $(row).attr('data-id', data['DT_DataId']);
                    }
                },
                "stateSave": true,
                "stateSaveCallback": function(settings, data) {
                    var tableIdentifier = $(settings.nTable).data('name');
                    if (typeof tableIdentifier === 'undefined')
                        tableIdentifier = (window.location.pathname).replace(/[- ._/]*/g, '');

                    var filterIdentifier = tableIdentifier + 'filters';

                    if (supports_html5_storage()) {
                        window.localStorage.setItem(tableIdentifier, JSON.stringify(data));

                        var table = $(settings['nTable']);
                        var filters = table.find('.filters th');
                        var result = new Object();
                        filters.each(function () {
                            var data = $(this).attr('data-col');
                            var values = new Array();

                            $(this).find('.datatable-filter').each(function() {
                                values.push($(this).val());
                            });

                            result[data] = values;
                        });

                        var visibility = table.find('.filters').is(':visible');
                        window.localStorage.setItem(filterIdentifier, JSON.stringify({filters: result, visibility: visibility}));
                    }
                },
                "stateLoadCallback": function(settings) {
                    var tableIdentifier = $(settings.nTable).data('name');
                    if (typeof tableIdentifier === 'undefined')
                        tableIdentifier = (window.location.pathname).replace(/[- ._/]*/g, '');

                    var filterIdentifier = tableIdentifier + 'filters';
                    if (supports_html5_storage()) {
                        var data = JSON.parse(window.localStorage.getItem(filterIdentifier));
                        var table = $(settings['nTable']);
                        if (data && typeof data.filters !== 'undefined') {
                            for (var key in data.filters) {
                                var i = 0;
                                table.find('.filters th[data-col="' + key +'"] .datatable-filter').each(function() {
                                    $(this).val(data.filters[key][i]);
                                    i++;
                                });
                            }

                            if (data.visibility) {
                                table.find('.filters').show();
                                table.find('.filter-control').toggle();
                            }
                        }

                        var data = window.localStorage.getItem(tableIdentifier);
                        return JSON.parse(data);
                    }
                    return false;
                },
                "initComplete": function() {
                    $('.variant-table .dataTables_wrapper').removeClass('form-inline');

                    setTimeout(() => $this.trigger('meebtables:init-complete', $this), 250);
                }
            };

            if (typeof orderColumn !== "undefined" && typeof orderDirection !== "undefined") {
                options["order"] = [[ orderColumn, orderDirection]];
            }

            if (typeof orderColumn === "undefined" || orderColumn === false) {
                options['ordering'] = false;
            }

            var serverSideLink = $(this).data('server-side-link');
            if (typeof serverSideLink !== 'undefined') {
                options.serverSide = true;
                options.ajax = {
                    url: serverSideLink,
                    type: 'POST',
                    data: function(data, settings){
                        var table = $(settings['nTable']);
                        var filters = table.find('.filters th');
                        var result = new Object();
                        filters.each(function () {
                            var data = $(this).attr('data-col');
                            var values = new Array();

                            $(this).find('.datatable-filter').each(function() {
                                values.push($(this).val());
                            });

                            result[data] = values;
                        });

                        console.log('showing loading overlay');
                        $this.addClass('loading-overlay');
                        data.filters = result;
                    },
                    complete: function() {
                        $this.removeClass('loading-overlay');
                        console.log('hiding loading overlay');
                    }
                };
            }

            var table = $(this).DataTable(options);
            var isLazyLoading = typeof $(this).data('server-side-link') !== 'undefined';

            $(this).find('.datatable-filter').change(function() {
                table.draw();
            });

            var handle = false;
            $(this).find('.datatable-filter').keyup(function() {
                if (isLazyLoading) {
                    if (handle !== false)
                        clearTimeout(handle);

                    handle = setTimeout(function () {
                        table.draw();
                    }, 500);
                } else {
                    table.draw()
                }
            });

            $(this).find('.date-filter').each(function () {
                initDatePickerElement($(this), true).on('dp.change', function() { table.draw(); });
            });

            $(this).find('.head-filter-toggle').click(function() {
                var target = $(this).closest('table');
                target.find('.filters').toggle();
                target.find('.filter-control').toggle();

                table.state.save();
            });

            if (options.serverSide) {
                $(this).off('.positionHandles');
                $(this).on('click.positionHandles', '.position-change-handle', function(e) {
                    e.preventDefault();
                    e.stopPropagation();

                    const href = $(this).attr('href');
                    $.post(href, function() {
                        table.ajax.reload();
                    });
                });
            }
        }
    });
}

function dataTablesDestroy(target) {
    const selector = '.dataTables';

    select(selector, target).each(function(elem) {
        if (typeof elem.DataTable !== "undefined") {
            elem.DataTable('destroy')
        }
    });
}

function small (count, masc) {
    switch(count) {
        case 2:
            return masc ? 'dva' : 'dvě';
        case 3:
            return 'tři';
        case 4:
            return 'čtyři';
    }
    return '';
}

function prettySelectTranslations() {
    return {
        errorLoading: function () {
            return 'Výsledky nemohly být načteny.';
        },
        inputTooLong: function (args) {
            var n = args.input.length - args.maximum;

            if (n == 1) {
                return 'Prosím, zadejte o jeden znak méně.';
            } else if (n <= 4) {
                return 'Prosím, zadejte o ' + small(n, true) + ' znaky méně.';
            } else {
                return 'Prosím, zadejte o ' + n + ' znaků méně.';
            }
        },
        inputTooShort: function (args) {
            var n = args.minimum - args.input.length;

            if (n == 1) {
                return 'Prosím, zadejte ještě jeden znak.';
            } else if (n <= 4) {
                return 'Prosím, zadejte ještě další ' + small(n, true) + ' znaky.';
            } else {
                return 'Prosím, zadejte ještě dalších ' + n + ' znaků.';
            }
        },
        loadingMore: function () {
            return 'Načítají se další výsledky…';
        },
        maximumSelected: function (args) {
            var n = args.maximum;

            if (n == 1) {
                return 'Můžete zvolit jen jednu položku.';
            } else if (n <= 4) {
                return 'Můžete zvolit maximálně ' + small(n, false) + ' položky.';
            } else {
                return 'Můžete zvolit maximálně ' + n + ' položek.';
            }
        },
        noResults: function () {
            return 'Nenalezeny žádné položky.';
        },
        searching: function () {
            return 'Vyhledávání…';
        },
        removeAllItems: function () {
            return 'Odstraňte všechny položky';
        }
    };
}

function initPrettySelect(target) {
    const selector = '.pretty-select';


    select(selector, target).each(function () {
        let options = {
            theme: 'bootstrap',
            language: prettySelectTranslations(),
        };

        let $this = $(this);
        if (typeof $this.data('tag') !== "undefined") {
            options.tags = true;
        }

        if (typeof $this.data('placeholder') !== "undefined") {
            options.placeholder = $this.data('placeholder');
        }

        if ($this.attr('readonly')) {
            options.readonly = true;
            options.disabled = true;
        }

        if (typeof $this.data('ajax') !== "undefined") {
            options.minimumInputLength = 3;
            options.ajax = { // instead of writing the function to execute the request we use Select2's convenient helper
                url: $this.data('ajax'),
                dataType: 'json',
                quietMillis: 250,
                data: function (term, page) {
                    return {
                        q: term, // search term
                    };
                },
                processResults: function (data, page) { // parse the results into the format expected by Select2.
                    // since we are using custom formatting functions we do not need to alter the remote JSON data
                    var result = $.map(data, function (value, key) {
                        return {id: key, text: value};
                    });

                    return {results: result};
                },
                cache: true
            };
        }

        $this.select2(options);
    });
}

function destroyPrettySelect(target) {
    const selector = $('.pretty-select');
    select(selector, target).each(function () {
        var $this = $(this);
        if ($this && $this.data('select2') && typeof $this !== "undefined" && typeof $this.select2 !== "undefined") {
            $this.select2('destroy');
        }
    });
}

function initIChecks(target) {
    const selector = '.i-checks';

    const defaults = {color: '#12C5A4'};
    select(selector, target).each(function() {
        let switchery = new Switchery(this, defaults);
        $(this).removeClass('i-checks');
    });
}

function destroyGallery() {
    if($("#frm-gallery-uploadForm-file").length){
        $("#frm-gallery-uploadForm-file").fileupload('destroy');
        console.log("Gallery destroyed.");
    }

}

$(document).on('click','.modal-submit',function () {
    $(this).closest('.modal').modal('toggle');
});

function recomputeNestableHeight(sortableOnly) {
    if (!sortableOnly)
        $("#structure-container").css('height',($("#structure-container").height()+40)+'px');

    $("#structure-container").css('height',($("#structure-container .sortable").height()+40)+'px');
}

function initReorderList(sendWholeList) {
    if (typeof sendWholeList === 'undefined') {
        sendWholeList = false;
    }

    $('.drag-and-drop-reorder').each(function () {
        var link = $(this).data('link');

        Sortable.create(this, {
            sort: true,

            onStart: function () {
                if (window.globalUpload)
                    window.globalUpload.overlay.disable();
            },
            onEnd: function () {
                if (window.globalUpload)
                    window.globalUpload.overlay.enable();
            },
            onSort: function (e) {
                showLoadingOverlay();

                if (sendWholeList) {
                    const parent = $(e.item).closest('.drag-and-drop-reorder');
                    let data = [];
                    parent.find('.list-group-item').each((key, value) => data.push($(value).data('id')));

                    let request = $.post(link, {'items': data }).done(function (data) {
                        updateSnippets(data);
                        hideLoadingOverlay();
                    });

                    return;
                }

                const itemId = $(e.item).data('id');
                const newOrder = e.newIndex;

                let request = $.post(link, {'item': itemId, 'order': newOrder }).done(function (data) {
                    updateSnippets(data);
                    hideLoadingOverlay();
                });
            }
        });
    });
}

function initSortable() {
    $('.sortable').each(function () {
        let isTree = true;
        if (typeof $(this).attr('data-list') !== 'undefined') {
            isTree = false;
        }

        let listType = 'ol';
        if (typeof $(this).attr('data-type') !== 'undefined') {
            listType = $(this).attr('data-type');
        }

        let maxLevel = 0;
        if (typeof $(this).attr('data-max-level') !== 'undefined') {
            maxLevel = $(this).attr('data-max-level');
        }

        $(this).nestedSortable({
            handle: 'div',
            items: 'li',
            toleranceElement: '> div',
            listType: listType,
            isTree: isTree,
            maxLevels: maxLevel,
            startCollapsed: true,
            relocate: function () {
                var order =  $('ol.sortable').nestedSortable('toArray', { startDepthCount: 0});
                var link = $('ol.sortable').data('link');

                var openedLists = [];
                $('.mjs-nestedSortable-expanded').each(function () {
                    openedLists.push($(this).data('id'));
                });

                showLoadingOverlay();
                $.ajax({
                    url: link,
                    method: 'POST',
                    data: {
                        order: order,
                    },
                }).done(function (data) {
                    updateSnippets(data);
                    recomputeNestableHeight(false);

                    openedLists.forEach(function (value) {
                        $('.dd-item[data-id="' + value + '"]').each(function () {
                            if (!$(this).hasClass('.mjs-nestedSortable-collapsed')) {
                                $(this).toggleClass('mjs-nestedSortable-collapsed').toggleClass('mjs-nestedSortable-expanded');
                                $(this).find('.disclose i.fa').toggleClass('fa-plus').toggleClass('fa-minus');
                            }
                        });
                    });

                    recomputeNestableHeight(true);
                    hideLoadingOverlay();
                });
            },
        }).after(function () {
            recomputeNestableHeight(false);

            $('.disclose').off('.disclose');
            $('.disclose').on('click.disclose', function() {
                $(this).closest('li').toggleClass('mjs-nestedSortable-collapsed').toggleClass('mjs-nestedSortable-expanded');
                $(this).children('i.fa').toggleClass('fa-plus').toggleClass('fa-minus');

                recomputeNestableHeight(true);
            });
        });
    });
}

function destroySortable() {
    if (typeof $('.sortable').sortable !== "undefined" && $('.sortable').hasClass('ui-sortable')) {
        $('.sortable').sortable('destroy');
        $('.disclose').off('click');
    }
}

$(document).ready( function () {
    $("#structure-container").css('height',($(".ui-sortable").height()+40)+'px');
});

function changeVisibility(target, show, reverse) {
    if (show) {
        if (reverse) {
            target.hide();
        } else {
            target.show();
        }
    } else {
        if (reverse) {
            target.show();
        } else {
            target.hide();
        }
    }
}

function initVisibilityDependentFields(target) {
    const selector = '*[data-visibility-field]';
    select(selector, target).each(function () {
        let $this = $(this);
        let $parent = $this.closest('.form-group');

        let targetName = $this.data('visibility-field');
        let targetType = $this.data('visibility-type');
        if (typeof targetType === 'undefined') {
            targetType = '*';
        }

        let form = $this.closest('form');

        let targetIdentifier = targetType + '[name="' + targetName + '"]';

        let isReversed = $this.data('visibility-reversed');
        let targetValue = $this.data('visibility-value');

        let $target = form.find(targetIdentifier);

        if ($target.is(':checkbox')) {
            changeVisibility($parent, $target.is(':checked'), isReversed);

            $target.on('ifChanged', function () {
                let checked = $(this).is(':checked');
                changeVisibility($parent, checked, isReversed);
            });
            $target.on('change', function() {
                let checked = $(this).is(':checked');
                changeVisibility($parent, checked, isReversed);
            });
        } else if ($target.is('select')) {
            changeVisibility($parent, $target.val() == targetValue, isReversed);

            $target.on('select2:select', function(e) {
                changeVisibility($parent, $(this).val() == targetValue, isReversed);
            });
            $target.on('change', function() {
                changeVisibility($parent, $(this).val() == targetValue, isReversed);
            });
        } else {
            changeVisibility($parent, $target.val() == targetValue, isReversed);

            $target.change(function () {
                changeVisibility($parent, $(this).val() == targetValue, isReversed);
            });
        }
    });
}

$(document).on('keyup','.meeb-menu-text-input', function(){
    $(this).css('width', (($(this).val().length) * 7) + 'px');

    $.ajax({
        url: '?do=editItemTitle',
        data: {
            translationId: $(this).data('translation-id'),
            title: $(this).val(),
        }
    });

}).each(function () {
    $(this).css('width', (($(this).val().length) * 7) + 'px');
});

$(document).on('click', "#meter-calculate-lower", function () {
    var width;

    if($.isNumeric($("#price-meter").val())){
        $(".variant-item").each( function () {

            width = parseInt($(this).find(".variant-primary-col").text()) / 1000;
            var price = parseFloat($("#price-meter").val());
            newPrice = price * width;

            var catalogue = $(this).find(".td-catalogue_price input").val(newPrice.toFixed(2));
            var discount = parseFloat($(this).find(".td-discount_lower input").val());
            var price = $(this).find(".td-price_lower input").val(((1 - (discount / 100)) * newPrice).toFixed(2));
        });
    }
});

$(document).on('click',"#meter-calculate-higher", function () {
    var width;

    if($.isNumeric($("#price-meter").val())){

        var width;

        $(".variant-item").each( function () {
            width = parseInt($(this).find(".variant-primary-col").text()) / 1000;
            var price = parseFloat($("#price-meter").val());
            newPrice = price * width;

            var catalogue = $(this).find(".td-catalogue_price_higher input").val(newPrice.toFixed(2));
            var discount = parseFloat($(this).find(".td-discount_higher input").val());
            var price = $(this).find(".td-price_higher input").val(((1 - (discount / 100)) * newPrice).toFixed(2));
        });
    }
});

$(document).on('keyup','input.custom-edit-field', function () {
    $.ajax({
        url: "?do=editField",
        data: {
            "newValue": $(this).val(),
            "fieldName": $(this).data('field-name'),
            "entityId": $(this).data("entity-id"),
            "table": $(this).data("table"),
        }
    });
});