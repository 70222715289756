function AutoGenerateManager(di, target) {
    this.snippetManager = di.getService('snippetManager');
    this.target = target;

    this.initialize();
}

AutoGenerateManager.prototype.initialize = function() {
    let self = this;
    select('.autogenerated', this.target).each(function () {
        self.initializeField(this);
    });

    select('.form-group .hidden', this.target).each(function () {
        $(this).closest('.form-group').hide();
    });

    let target = typeof this.target === "undefined" ? document : '#' + this.target;
    $(target).on('click.autogeneration', '.custom-value-enable', function () {
        self.toggleAutoGeneration(this);
    });
};

AutoGenerateManager.prototype.toggleAutoGeneration = function(element) {
    var $parent = $(element).closest('.form-group');
    var autoField = $parent.find('.autogenerated');
    var name = autoField.attr('name');

    var target = $('input[name="Custom' + name + '"]');
    if (this.isAutoGenerationEnabled(name)) {
        target.attr('checked', 'checked');
    } else {
        target.attr('checked', false);
    }

    $parent.find('.custom-value-info').remove();
    this.updateAutoGenerationInfo(autoField);
};

AutoGenerateManager.prototype.initializeField = function(element) {
    this.initializeHandlers(element);
    this.initializeCustomValue(element);
};

AutoGenerateManager.prototype.initializeCustomValue = function(element) {
    var $element = $(element);

    if (this.hasElementAttribute($element, 'data-html-disable')) {
        this.updateAutoGenerationInfo(element);
    }
};

AutoGenerateManager.prototype.updateAutoGenerationInfo = function(element) {
    var $element = $(element);
    var elementName = $element.attr('name');
    if (this.isAutoGenerationEnabled(elementName)) {
        if ($element.parent().find('.custom-value-info').length > 0) {
            return;
        }

        $element.parent().append($element.data('html-disable'));
        $element.attr('readonly', true);

        var press = jQuery.Event("keyup");
        press.ctrlKey = false;
        press.which = 40;

        var targetName = $element.data('target');
        $('input[name="' + targetName + '"]').trigger(press);
    } else {
        $element.parent().append($element.data('html-enable'));
        $element.attr('readonly', false);
    }
};

AutoGenerateManager.prototype.isAutoGenerationEnabled = function(name) {
    return $('input[name="Custom' + name + '"]').attr('checked') !== 'checked';
};

AutoGenerateManager.prototype.hasElementAttribute = function(element, attribute) {
    var attr = $(element).attr(attribute);

    return typeof attr !== "undefined" && attr !== false;
};

AutoGenerateManager.prototype.initializeHandlers = function(element) {
    var $element = $(element);
    var targetName = $element.data('target');
    var $target = $('input[name="' + targetName + '"]');
    if ($target.data('initialized')) {
        return;
    }

    $target.find('.form-group').find('.custom-value-info').remove();
    $target.data('initialized', true);
    var link = $element.data('link');

    var timer = false;
    var request = false;

    var self = this;
    $target.on('keyup.autogeneration', function () {
        if (timer) {
            clearTimeout(timer);
            timer = false;
        }

        if (request) {
            request.abort();
            request = false;
        }

        if (!self.isAutoGenerationEnabled($element.attr('name'))) {
            return false;
        }

        timer = setTimeout(function () {
            var data = {field_name: $element.attr('name'), field_value: $target.val()};
            request = $.post(link, data, function (response) {
                $element.val(response.result);
            })
        }, 500);
    });
};

AutoGenerateManager.prototype.unbind = function() {
    select('input', this.target).off('.autogeneration');
};

AutoGenerateManager.prototype.destroy = function() {
    this.unbind();
};