let AdminModeInstance = (function () {
    let instance;

    function createInstance(di, autoScrollManager) {
        let object = new AdminMode(di, autoScrollManager);
        return object;
    }

    return {
        getInstance: function (di, autoScrollManager) {
            if (!instance) {
                instance = createInstance(di, autoScrollManager);
            }

            return instance;
        }
    };
})();

function AdminMode(di, autoScrollManager) {
    this.page = di.getService('page');
    this.snippetManager = di.getService('snippetManager');
    this.autoScrollManager = autoScrollManager;
    this.progressBar = false;
}

AdminMode.prototype.initialize = function() {
    let self = this;
    this.snippetManager.on('after-update', function () {
        $(document).off('admin-mode');
        self.bindHandlers();

        $('.meeb__admin_edit_area_wrapper').remove();
    });
}

AdminMode.prototype.bindHandlers = function() {
    let self = this;
    $(document).off('.admin-mode');
    $(document).on('click.admin-mode', '.meeb__admin_static_block_wrapper', function (e) {
        e.stopPropagation();
        e.preventDefault();

        let $this = $(this);
        self.showAdminOverlay();
        let link = $('.meeb__admin-overlay').data('link');
        let code = $this.data('code');
        let type = $this.data('type');

        self.page.open(link, 'POST', { code: code, type: type}, { history: false });
        return false;
    });

    $('#overlay-search').keyup(function () {
        let query = $(this).val();
        $('.meeb__admin-overlay-nav li ul li').each(function() {
            let present = $(this).text().toString().includes(query);

            if (present) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
    });

    $('#meeb__overlay-trigger').click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        self.showAdminOverlay();
    });
}

AdminMode.prototype.initEditArea = function(target) {
    let self = this;
    /*target.find('.autosubmit').change(function() {
        $(this).find('form').submit();
    });*/

    let timer = false;
    target.find('.autotext').change(function () {
        if (timer) {
            clearTimeout(timer);
            timer = false;
        }

        self.updateText($(this));
    });
    target.find('.autotext').keyup(function () {
        if (timer) {
            clearTimeout(timer);
        }

        let $this = $(this);
        timer = setTimeout(function () {
            self.updateText($this);
            timer = false;
        }, 450);
    });

    target.find('.wysiwyg-editor').summernote({
        callbacks: {
            onChange: function(contents) {
                if (timer) {
                    clearTimeout(timer)
                }

                let $this = $(this);
                timer = setTimeout(function () {
                    self.updateText($this);
                    timer = false;
                }, 450);
            }
        }
    });
}

AdminMode.prototype.updateText = function(elem) {
    if (this.progress) {
        this.progress.removeBar();
        this.progress = false;
    }

    this.progress = new ProgressBar(elem);
    let form = elem.closest('form');
    let action = form.attr('action');
    let data = form.serializeArray();

    let self = this;
    $.post(action, data, function() {
        self.progress.removeBar();
        self.progress = false;
    });
}

AdminMode.prototype.showAdminOverlay = function() {
    $('body').addClass('meeb__admin-overlay-open');
}

AdminMode.prototype.hideAdminOverlay = function () {
    $('body').removeClass('meeb__admin-overlay-open');
}

