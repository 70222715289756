function ProgressBar($target, $wrapper, target) {
    this.target = $target;
    this.progress = 0;
    this.progressStep = 2;
    this.progressDelay = 100;
    this.timer = false;

    var progress = this.createProgressBar();
    if ($wrapper) {
        if (target) {
            $wrapper.find(target).html(progress);
        } else {
            $wrapper.html(progress);
        }

        this.barWrapper = $wrapper;
    } else {
        this.barWrapper = progress;
    }

    this.barLine = this.barWrapper.find('.progress-bar');

    this.barWrapper.insertBefore($target);
    var self = this;
    this.timer = setTimeout(function () {
        self.addProgress();
    }, this.progressDelay);
};

ProgressBar.prototype.updateProgress = function() {
    this.barLine.width(this.progress + '%');
};

ProgressBar.prototype.addProgress = function() {
    this.progress += this.progressStep;
    this.progressDelay *= 1.05;

    this.updateProgress();
    if (this.progress < 100 - this.progressStep) {
        var self = this;
        this.timer = setTimeout(function () {
            self.addProgress();
        }, this.progressDelay);
    }
};

ProgressBar.prototype.removeBar = function() {
    clearTimeout(this.timer);
    this.timer = null;

    this.progress = 100;
    this.updateProgress();

    var self = this;
    setTimeout(function () {
        self.barWrapper.fadeOut(function () {
            self.barWrapper.remove();
        });
    }, 500);
};

ProgressBar.prototype.createProgressBar = function() {
    return $('<div class="progress progress-striped progress-slim mb-0"><div style="width: 0%" aria-valuemax="100" aria-valuemin="0" aria-valuenow="75" role="progressbar" class="progress-bar"></div></div>')
};