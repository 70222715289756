function initMosetTranslations(target, di) {
    const block = $('#' + target);
    block.off('.translations');
    block.on('keyup.translations', '#translation-search', function(e) {
        e.preventDefault();
        e.stopPropagation();

        const val = $(this).val().toLowerCase();

        $('#translation-table tbody tr').each(function() {
            const row = $(this);
            let found = false;
            row.find('td').each(function() {
                if (!found) {
                    const text = $(this).text().toLowerCase();
                    found = text.includes(val);
                }
            });

            if (found) {
                row.show();
            } else {
                row.hide();
            }
        });
    });

    block.on('click.translations', '#next-translation', function(e) {
        findNextRow(e, 1, di);
    });
    block.on('click.translations', '#prev-translation', function(e) {
        findNextRow(e, -1, di);
    });

    block.on('keyup.translations', '#original', function(e) {
        processKeyUp(e, $(this), di);
    });
    block.on('keyup.translations', '#translation', function(e) {
        processKeyUp(e, $(this), di);
    });
}

let timers = {};
function registerTimer(name, timer) {
    timers[name] = timer;
}

function clearTimer(name) {
    if (typeof timers[name] !== 'undefined') {
        clearTimeout(timers[name]);
    }
}

function processKeyUp(e, elem, di) {
    e.preventDefault();
    e.stopPropagation();

    const elemId = elem.attr('id');
    clearTimer(elemId);

    const timer = setTimeout(function() {
        updateTranslations(elem, di);
    }, 500);

    registerTimer(elemId, timer);
}

function updateTranslations(elem, di) {
    const val = elem.val();
    const link = elem.data('link');

    const spinner = elem.closest('.input-group').find('.input-group-append');
    spinner.removeClass('d-none');
    $.post(link, {text: val}, function (data) {
        spinner.addClass('d-none');

        const cols = $('#translation-table tr[data-code="' + data['code'] + '"] td a');
        if (cols.length > 0) {
            $(cols.get(0)).text(data['code']);
            $(cols.get(1)).text(data['leftText']);
            $(cols.get(2)).text(data['rightText']);
        }
    });

    timer = false;
}

function findNextRow(e, direction, di) {
    e.stopPropagation();
    e.preventDefault();

    const code = $('#original').data('code');
    if (typeof code === 'undefined') return;

    const tableSelector = '#translation-table tr:visible';

    let found = false;
    $(tableSelector).each(function(id) {
        const text = $(this).attr('data-code');
        if (text === code) {
            found = id;
        }
    });

    if (found !== false) {
        found += direction;
        if (found < 1) {
            found = $(tableSelector).length - 1;
        }

        if ($(tableSelector).length <= found) {
            found = 1;
        }

        const td = $(tableSelector).get(found);
        const link = $(td).find('a').first().attr('href');

        di.getService('page').open(link);
    }
}
