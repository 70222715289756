let MosetInstance = (function () {
    let instance;

    function createInstance(di, autoScrollManager) {
        let object = new Moset(di, autoScrollManager);
        return object;
    }

    return {
        getInstance: function (di, autoScrollManager) {
            if (!instance) {
                instance = createInstance(di, autoScrollManager);
            }

            return instance;
        }
    };
})();

function Moset(di, autoScrollManager) {
    this.di = di;
    this.autoScrollManager = autoScrollManager;

    this.openId = "#meeb__overlay-trigger-show";
    this.closeId = "#meeb__overlay-trigger-close";
    this.dragId = "#meeb__admin-toolbar-drag-drop";
    this.controlId = "#meeb__admin-toolbar-main";
    this.pageId = "#meeb__overlay-trigger-show";
    this.collapsedId = '#meeb__admin-toolbar-collapsed';
    this.translationsId = '#meeb__translations-trigger-show';
    this.translationsOverlayId = '#meeb__admin_overlay';
    this.translationsLinkId = '#translations-links';
    this.translationsBodyClass = 'overlay-show';
    this.versionsControlId = '#meeb__admin-toolbar-versions-handle';
    this.versionsListId = '#meeb__admin-tooblar-versions-list';
    this.versionsLinksClass = '.meeb__admin-version-link';
    this.versionsHiddenClass = 'meeb__admin-tool-list-hidden';

    this.versionsListPosLeft = 'meeb__list-pos-left';
    this.versionsListPosRight = 'meeb__list-pos-right';

    this.snappedClass = 'meeb__admin-toolbar-snapped';
    this.pageManager = AdminPageContentInstance.getInstance(this.di, this.autoScrollManager);

    this.mouseX = 0;
    this.mouseY = 0;

    this.elementX = 0;
    this.elementY = 0;

    this.width = 110;
    this.height = 535;

    this.restorePosition();
    this.restoreState();

    let self = this;

    /* TODO linky se otevrou a zavrou by default
    $(this.openId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.open($(this));
    });
    */

    $(this.versionsControlId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.toggleVersions($(this));
    });

    $(document).off('.moset-version-handles')
    $(document).on('click.moset-version-handles', this.versionsLinksClass, function(e) {
        e.preventDefault();
        e.stopPropagation();

        const pageId = $('#page-id-holder').data('id');
        const href = $(this).attr('href');
        self.di.getService('page').open(href, 'POST', { pageId: pageId }, { history: false }).then(function() {
            self.refreshVersions();
        });
    });

    $(this.pageId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.show();
    });

    $(this.translationsId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.toggleTranslations($(this));
    });

    $(this.closeId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.hideTranslations($(this.translationsId)).then(function() {
            self.pageManager.hideOverlay();
            $(self.pageId).removeClass('active');

            self.hideLinks();
            self.savePosition();
            self.collapse();
        });
    });

    $(this.collapsedId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        let body = $('body');
        body.removeClass(self.snappedClass);
        let cls = body.attr('class');

        let offset = 15;
        let x = self.elementX;
        let y = self.elementY;

        if (cls.indexOf('top-left') !== -1) {
            x += offset;
            y += offset;
        } else if (cls.indexOf('top-right') !== -1) {
            x -= offset;
            y += offset;
        } else if (cls.indexOf('bottom-left') !== -1) {
            x += offset;
            y -= offset;
        } else if (cls.indexOf('bottom-right') !== 1) {
            x -= offset;
            y -= offset;
        }

        self.changePosition(x, y);
        self.saveState(false);
        self.openLinks();
    });

    this.initDragging();
}

Moset.prototype.checkVersionListTopOffset = function() {
    const versionsList = $(this.versionsListId);

    const height = versionsList.outerHeight();
    const offset = Math.max(height - 175, 0);

    //versionsList.attr('style', 'top: -' + offset + 'px');
    versionsList.css('top', -offset + 'px');
}

Moset.prototype.checkVersionListPosition = function() {
    const versionsList = $(this.versionsListId);
    const positionClass = (this.elementX + this.width * 0.5)  >  (window.innerWidth * 0.5) ? this.versionsListPosLeft : this.versionsListPosRight;

    versionsList.removeClass(this.versionsListPosLeft);
    versionsList.removeClass(this.versionsListPosRight);
    versionsList.addClass(positionClass);
}

Moset.prototype.toggleVersions = function(elem) {
    const self = this;
    const versionsList = $(self.versionsListId);

    if (versionsList.hasClass(self.versionsHiddenClass)) {
        $(this.versionsControlId).addClass('disabled');
        self.checkVersionListPosition();

        this.refreshVersions().then(function() {
            versionsList.removeClass(self.versionsHiddenClass);
            $(self.versionsControlId).removeClass('disabled');
            self.checkVersionListTopOffset();
        });

        return;
    }

    versionsList.addClass(this.versionsHiddenClass);
}

Moset.prototype.refreshVersions = function() {
    const promise = new Promise((resolve, reject) => {
        const target = $(this.versionsControlId);
        const pageId = $('#page-id-holder').data('id');
        const link = target.attr('href');

        const list = $(this.versionsListId);
        list.html('');

        const self = this;
        $.post(link, { pageId: pageId }, function(data) {
            if (typeof data['page'] !== 'undefined') {
                list.append($('<li><span class="font-weight-bold w-100">Stránka</span></li>'))
                self.fillVersionList(data['page']);
            }

            if (typeof data['footer'] !== 'undefined') {
                list.append($('<li><span class="font-weight-bold w-100">Patička</span></li>'))
                self.fillVersionList(data['footer']);
            }
            resolve();
        });
    });

    return promise;

    /*page.open(link, 'POST', { pageId: pageId }, { history: false, transition: false }).then(function(data) {
        console.log(data);
        self.fillVersionList(data);
    });*/
}

Moset.prototype.fillVersionList = function(data) {
    const list = $(this.versionsListId);
    data.forEach((val) => list.append(this.createVersionElement(val)));
}

Moset.prototype.createVersionElement = function(version) {
    const elem = $('<li></li>');
    elem.addClass('meeb__admin-version-link');
    elem.append($('<span>' + version.name + '</span>'));
    elem.append($('<span>' + version.createdAt + '</span>'));
    elem.append($('<span>' + version.updatedAt + '</span>'));
    elem.append($('<span>' + version.publishedAt + '</span>'));
    //elem.append($('<span>' + version.primary + '</span>'));

    if (version.selected) {
        if (version.primary) {
            elem.append($('<span>Primární verze</span>'));
        } else {
            elem.append($('<span><a class="meeb__admin-version-link" href="' + version.linkRemove + '">Vypnout zobrazení</a></span>'));
            elem.addClass('meeb__admin-version-primary');
        }
    } else {
        elem.append($('<span><a class="meeb__admin-version-link" href="' + version.linkAdd + '">Zapnout zobrazení</a></span>'));
    }

    return elem;
}

Moset.prototype.toggleTranslations = function(elem) {
    const target = $(this.translationsOverlayId);
    if (target.hasClass('show')) {
        this.hideTranslations(elem);
    } else {
        this.openTranslations();
    }
}

Moset.prototype.show = function() {
    this.pageManager.toggleOverlay();
    $(this.pageId).toggleClass('active');
}

Moset.prototype.openTranslations = function() {
    $(this.translationsOverlayId).addClass('show');
    $('body').addClass(this.translationsBodyClass);
}

Moset.prototype.hideTranslations = function(elem) {
    const self = this;
    return new Promise(function (resolve, reject) {
        if (!$(self.translationsOverlayId).hasClass('show')) {
            $(self.translationsOverlayId).removeClass('show');
            $('body').removeClass(self.translationsBodyClass);

            resolve();
            return;
        }

        const link = $(self.translationsOverlayId).data('close-link');
        $(self.controlId).find('.nav-link').addClass('disabled');

        window.isScrollEnabled = false;
        self.di.getService('page').open(link).then(function() {
            $(self.controlId).find('.nav-link').removeClass('disabled');

            $(self.translationsOverlayId).removeClass('show');
            $('body').removeClass(self.translationsBodyClass);
            resolve();
        });
    });
}

Moset.prototype.openLinks = function() {
    $('body').addClass('meeb__admin-show-links');
}

Moset.prototype.hideLinks = function() {
    $('body').removeClass('meeb__admin-show-links');
}

Moset.prototype.initDragging = function() {
    let self = this;

    $(this.dragId).mousedown(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.mouseX = e.clientX;
        self.mouseY = e.clientY;

        let position = $(self.controlId).position();
        self.changePosition(position.left, position.top);

        self.dragStarted();
    });
}

Moset.prototype.dragStarted = function() {
    let self = this;
    $(document).removeClass(this.snappedClass);

    $(document).on('mousemove.moset', function(e) {
        e.preventDefault();
        e.stopPropagation();

        let changeX = e.clientX - self.mouseX;
        let changeY = e.clientY - self.mouseY;

        self.changePosition(self.elementX + changeX, self.elementY + changeY);
        self.mouseX = e.clientX;
        self.mouseY = e.clientY;
    });

    $(document).on('mouseup.moset', function(e) {
        e.preventDefault();
        e.stopPropagation();

        $(document).off('.moset');
        self.dragEnded();
    });
}

Moset.prototype.getBoundaries = function() {
    return {
        x: window.innerWidth,
        y: window.innerHeight,
    };
}

Moset.prototype.checkBoundaries = function() {
    let limit = this.getBoundaries();

    let elem = $(this.controlId);
    let elementHeight = elem.height();
    let elementWidth = elem.width();
    if (isNaN(elementHeight)) elementHeight = 0;
    if (isNaN(elementWidth)) elementWidth = 0;

    this.elementX = Math.max(0, Math.min(limit.x - elementWidth, this.elementX));
    this.elementY = Math.max(0, Math.min(limit.y - elementHeight, this.elementY));

    let result = false;
    let midBottom = limit.y * 0.5;
    let midRight = limit.x * 0.5;

    let elX = this.elementX;
    let elY = this.elementY;

    if (elX <= 0) {
        result = ((elY + elementHeight * 0.5) > midBottom ? "bottom" : "top")  + "-left";
    } else if (elX >= limit.x - elementWidth) {
        result = ((elY + elementHeight * 0.5) > midBottom ? "bottom" : "top") + "-right";
    } else if (elY >= limit.y - elementHeight) {
        result = "bottom-" + (elX > midRight ? "right" : "left");
    } else if (elY <= 0) {
        result = "top-" + (elX > midRight ? "right" : "left");
    }

    if (result !== false) {
        result = "meeb__admin-toolbar-" + result;
    }

    return result;
}

Moset.prototype.removeBodyClass = function (cls) {
    let body = $('body');
    if (body.hasClass(cls)) body.removeClass(cls);
}

Moset.prototype.cleanBoundariesClasses = function(except) {
    let bottomLeft = 'meeb__admin-toolbar-bottom-left';
    let bottomRight = 'meeb__admin-toolbar-bottom-right';
    let topLeft = 'meeb__admin-toolbar-top-left';
    let topRight = 'meeb__admin-toolbar-top-right';

    if (except !== bottomLeft) this.removeBodyClass(bottomLeft);
    if (except !== bottomRight) this.removeBodyClass(bottomRight);
    if (except !== topLeft) this.removeBodyClass(topLeft);
    if (except !== topRight) this.removeBodyClass(topRight);
}

Moset.prototype.changePosition = function(x, y, checkBoundaries) {
    this.elementX = x;
    this.elementY = y;

    if (typeof checkBoundaries === 'undefined') {
        checkBoundaries = true;
    }

    if (checkBoundaries) {
        let cls = this.checkBoundaries();
        if (cls !== false) {
            this.cleanBoundariesClasses(cls);
            $('body').addClass(cls);
        } else {
            this.cleanBoundariesClasses();
        }
    }

    if (!$(this.versionsListId).hasClass(this.versionsHiddenClass)) {
        this.checkVersionListPosition();
    }

    this.savePosition();
    let target = $(this.controlId);

    target.css('top', this.elementY);
    target.css('left', this.elementX);
}

Moset.prototype.dragEnded = function() {
    let cls = this.checkBoundaries();
    if (cls !== false) {
        $('body').addClass(this.snappedClass);
        this.saveState(true);
        this.hideLinks();
    }
}

Moset.prototype.collapse = function() {
    let limit = this.getBoundaries();

    let elem = $(this.controlId);
    let elementHeight = elem.height();
    if (isNaN(elementHeight)) elementHeight = 0;

    let midBottom = limit.y * 0.5;
    let midRight = limit.x * 0.5;

    let elX = this.elementX;
    let elY = this.elementY;

    let result = ((elY + elementHeight * 0.5) > midBottom ? "bottom" : "top");
    result = result + '-' + (elX > midRight ? "right" : "left");
    result = 'meeb__admin-toolbar-' + result;

    let body = $('body');
    body.addClass(this.snappedClass);
    body.addClass(result);

    this.hideLinks();
    this.saveState(true);
}

Moset.prototype.savePosition = function() {
    if (typeof(Storage) !== "undefined") {
        // Store
        localStorage.setItem("mosetPosX", this.elementX);
        localStorage.setItem("mosetPosY", this.elementY);
    }
}

Moset.prototype.restorePosition = function() {
    if (typeof(Storage) !== "undefined") {
        let x = parseInt(localStorage.getItem('mosetPosX'));
        let y = parseInt(localStorage.getItem('mosetPosY'));

        this.changePosition(x, y, false);
        //this.dragEnded();
    }

    return false;
}

Moset.prototype.saveState = function(isCollapsed) {
    if (typeof(Storage) !== "undefined") {
        localStorage.setItem("mosetStateCollapsed", isCollapsed);
    }
}

Moset.prototype.restoreState = function() {
    if (typeof(Storage) !== "undefined") {
        let isCollapsed = localStorage.getItem("mosetStateCollapsed") === 'true';
        if (isCollapsed) {
            this.collapse();
        } else {
            let body = $('body');
            body.removeClass(this.snappedClass);
            this.cleanBoundariesClasses();

            this.openLinks();
        }

        return;
    }

    this.collapse();
}