let MeebsterIframeInstance = (function () {
    let instance;

    function createInstance() {
        let object = new MeebsterIframeManager();
        return object;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }

            return instance;
        }
    };
})();

function MeebsterIframeManager() {
    this.overlayId = '#meeb__iframe-overlay';
    this.iframe = $('#meeb__admin-iframe');
    this.bodyHideLinksClass = 'meeb__admin-hide-links';
}

MeebsterIframeManager.prototype.toggle = function(link) {
    const visible = $(this.overlayId).hasClass('show');
    if (visible) {
        this.hide();
    } else {
        this.show(link);
    }
}

MeebsterIframeManager.prototype.show = function(link) {
    $(this.iframe).attr('src', link);
    $(this.overlayId).addClass('show');
}

MeebsterIframeManager.prototype.hide = function() {
    $(this.overlayId).removeClass('show');
}