function MeebLoadingOverlay() {
    this.overlay = null;
}

MeebLoadingOverlay.prototype.show = function() {
    if (this.overlay === null) {
        $('body').prepend(this.generateOverlay());
        this.overlay = $('#loader-overlay');
        this.overlay.fadeIn('fast');
    }
};

MeebLoadingOverlay.prototype.hide = function() {
    if (this.overlay !== null) {
        this.overlay.fadeOut('fast', function () {
            $('#loader-overlay').remove();
        });

        this.overlay = null;
    }
};

MeebLoadingOverlay.prototype.isOverlayActive = function() {
    return this.overlay !== null;
}

MeebLoadingOverlay.prototype.generateOverlay = function() {
    return '<div id="loader-overlay" class="overlay" style="z-index: 999999999999999;">' +
        '    <div class="loader">' +
        '    </div>' +
        '</div>';
};