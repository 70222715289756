(function($) {
  $.extend($.summernote.lang, {
    'cs-CZ': {
      font: {
        bold: 'Tučně',
        italic: 'Kurzíva',
        underline: 'Podtrženě',
        clear: 'Odstranit styl písma',
        height: 'Výška řádku',
        strikethrough: 'Přeškrtnuto',
        size: 'Velikost písma',
        superscript: 'Horní index',
        subscript: 'Dolní index',
      },
      image: {
        image: 'Obrázek',
        insert: 'Vložit obrázek',
        resizeFull: 'Původní velikost',
        resizeHalf: 'Poloviční velikost',
        resizeQuarter: 'Čtvrtinová velikost',
        floatLeft: 'Umístit doleva',
        floatRight: 'Umístit doprava',
        floatNone: 'Neobtékat textem',
        shapeRounded: 'Tvar: zakulacený',
        shapeCircle: 'Tvar: kruh',
        shapeThumbnail: 'Tvar: náhled',
        shapeNone: 'Tvar: žádný',
        dragImageHere: 'Přetáhnout obrázek sem',
        dropImage: 'Přetáhnout obrázek sem',
        selectFromFiles: 'Vybrat soubor',
        url: 'URL obrázku',
        remove: 'Smazat obrázek',
        original: 'Původní'
      },
      video: {
        video: 'Video',
        videoLink: 'Odkaz videa',
        insert: 'Vložit video',
        url: 'URL videa',
        providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion nebo Youku)'
      },
      link: {
        link: 'Odkaz',
        insert: 'Vytvořit odkaz',
        unlink: 'Zrušit odkaz',
        edit: 'Upravit',
        textToDisplay: 'Zobrazený text',
        url: 'Na jakou url má tento odkaz vést',
        openInNewWindow: 'Otevřít v novém okně›'
      },
      table: {
        table: 'Tabulka',
        addRowAbove: 'Přidat řádek nad',
        addRowBelow: 'Přidat řádek pod',
        addColLeft: 'Přidat sloupec vlevo',
        addColRight: 'Přidat sloupec vpravo',
        delRow: 'Odstranit řádek',
        delCol: 'Odstranit sloupek',
        delTable: 'Smazat tabulku'
      },
      hr: {
        insert: 'Vložit vodorovnou čáru'
      },
      style: {
        style: 'Styl',
        p: 'Normální',
        blockquote: 'Citace',
        pre: 'Kód',
        h1: 'Nadpis 1',
        h2: 'Nadpis 2',
        h3: 'Nadpis 3',
        h4: 'Nadpis 4',
        h5: 'Nadpis 5',
        h6: 'Nadpis 6'
      },
      lists: {
        unordered: 'Nečíslovaný seznam',
        ordered: 'Číslovaný seznam'
      },
      options: {
        help: 'Nápověda',
        fullscreen: 'Celá obrazovka',
        codeview: 'Zdrojový kód'
      },
      paragraph: {
        paragraph: 'Odstavec',
        outdent: 'Zvětšit odsazení',
        indent: 'Zmenšit odsazení',
        left: 'Zarovnat doleva',
        center: 'Zarovnat na střed',
        right: 'Zarovnat doprava',
        justify: 'Zarovnat oboustranně›'
      },
      color: {
        recent: 'Aktuální barva',
        more: 'Další barvy',
        background: 'Barva pozadí',
        foreground: 'Barva popředí',
        transparent: 'Průhlednost',
        setTransparent: 'Nastavit průhlednost',
        reset: 'Obnovit',
        resetToDefault: 'Obnovit výchozí'
      },
      shortcut: {
        shortcuts: 'Klávesové zkratky',
        close: 'Zavřít',
        textFormatting: 'Formátování textu',
        action: 'Akce',
        paragraphFormatting: 'Formátování odstavce',
        documentStyle: 'Styl dokumentu'
      },
      help: {
        'insertParagraph': 'Vložit odstavec',
        'undo': 'Vzít zpět poslední akci',
        'redo': 'Vrátit poslední akci',
        'tab': 'Tab',
        'untab': 'Untab',
        'bold': 'Set a bold style',
        'italic': 'Set a italic style',
        'underline': 'Set a underline style',
        'strikethrough': 'Set a strikethrough style',
        'removeFormat': 'Clean a style',
        'justifyLeft': 'Set left align',
        'justifyCenter': 'Set center align',
        'justifyRight': 'Set right align',
        'justifyFull': 'Set full align',
        'insertUnorderedList': 'Toggle unordered list',
        'insertOrderedList': 'Toggle ordered list',
        'outdent': 'Outdent on current paragraph',
        'indent': 'Indent on current paragraph',
        'formatPara': 'Change current block\'s format as a paragraph(P tag)',
        'formatH1': 'Change current block\'s format as H1',
        'formatH2': 'Change current block\'s format as H2',
        'formatH3': 'Change current block\'s format as H3',
        'formatH4': 'Change current block\'s format as H4',
        'formatH5': 'Change current block\'s format as H5',
        'formatH6': 'Change current block\'s format as H6',
        'insertHorizontalRule': 'Insert horizontal rule',
        'linkDialog.show': 'Show Link Dialog'
      },
      history: {
        undo: 'Krok vzad',
        redo: 'Krok vpřed'
      },
      specialChar: {
        specialChar: 'SPECIÁLNÍ ZNAKY',
        select: 'Vyberte speciální znak'
      }
    }
  });
})(jQuery);