function askPermission() {
    return Notification.requestPermission().then(function(result) {
        if (result !== 'granted') {
            throw new Error('User did not granted us permission')
        }
    });
}

function subscribeUserToPush(publicKey) {
    if (!('serviceWorker' in navigator)) {
        // Service Worker isn't supported on this browser, disable or hide UI.
        return false;
    }

    if (!('PushManager' in window)) {
        // Push isn't supported on this browser, disable or hide UI.
        return false;
    }

    return navigator.serviceWorker
        .register('/service-worker.js')
        .then(function (registration) {
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                    publicKey,
                ),
            };

            return registration.pushManager.subscribe(subscribeOptions);
        })
        .then(function (pushSubscription) {
            console.log(
                'Received PushSubscription: ',
                JSON.stringify(pushSubscription),
            );

            // TODO budeme potrebovat poslat na server a ulozit do subscriptions
            return pushSubscription;
        });
}