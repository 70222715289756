function initRegistrationForm() {
    $('input[name="billing_info"]').change(function () {
        if ($(this).is(':checked')) {
            $('#billing').show();
        } else {
            $('#billing').hide();
        }
    });

    $('input[name="address_shipping"]').change(function () {
        if ($(this).is(':checked')) {
            $('#address-shipping').show();
        } else {
            $('#address-shipping').hide();
        }
    });

    var visibilityTargets = [];
    $('*[data-visibility-target]').each(function () {
        var $this = $(this);
        var name = $this.data('visibility-target');
        var $target = $('*[name= "' + name +'"]');
        visibilityTargets.push($target);

        var val = $target.val();
        updateElementVisibility($this, val);
    });

    for (var key in visibilityTargets) {
        var input = visibilityTargets[key];
        
        input.change(function () {
            var $this = $(this);
            var val = $this.val();
            var name = $this.attr('name');

            $('*[data-visibility-target="' + name +'"]').each(function () {
                updateElementVisibility($(this), val);
            });
        });
    }
}

function updateElementVisibility($elem, val)
{
    var targetIds = $elem.data('visibility-values');
    var found = false;
    for (var key in targetIds) {
        if (targetIds[key] == val) {
            found = true;
            break;
        }
    }

    if (found) {
        $elem.closest('.form-group').show();
    } else {
        $elem.closest('.form-group').hide();
    }
}