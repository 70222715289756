"use strict";

let FlashMessageManager = (function () {
    let instance;

    return {
        getInstance: function (di) {
            if (!instance) {
                instance = new Manager(di);
            }

            return instance;
        }
    };

    function Manager(di) {
        this.di = di;

        this.init = function() {
            const snippetManager = di.getService('snippetManager');
            const self = this;

            snippetManager.on('after-update', function() {
                if ($('.nittro-flash').length > 0) {
                    $('.nittro-flash').each(function() {
                        let type = 'info';
                        if (this.classList.length > 2) {
                            type = this.classList[1].replace('nittro-flash-', '');
                        }

                        self.addMessage($(this).text(), type);
                    });
                }
            });

            $('.flash-message-manager');
            $('body').on('click.flash-message-manager', '.nittro-flash', function(e) {
                e.stopPropagation();
                e.preventDefault();

                self.removeMessage($(this));
            });
        }

        this.removeMessage = function(elem) {
            const parent = elem.parent();
            if (parent.find('.nittro-flash').length <= 1) {
                parent.removeClass('show');
                setTimeout(() => elem.remove(), 1000);
                return;
            }

            elem.remove();
        }

        this.addMessage = function(message, type) {
            if (typeof type === 'undefined') {
                type = 'info';
            }

            let el = null;
            if (this.isMessageUnique(message)) {
                el = $('<p class="nittro-flash nittro-flash-inline nittro-flash-' + type +'">' + message + '</p>');
                $('.flashes-wrapper').append(el);
            }

            this.showMessages();
            return el;
        }

        this.isMessageUnique = function(message) {
            let result = true;
            $('.flashes-wrapper .nittro-flash').each(function() {
                if ($(this).html() === message) {
                    result = false;
                    return false;
                }
            });

            return result;
        }

        this.showMessages = function() {
            $('.logo').addClass('pulse');
            $('.flashes-wrapper').addClass('show');

            setTimeout(function () {
                $('.logo').removeClass('pulse');
            }, 1000)
        }

        this.init();
    }

})();