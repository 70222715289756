function initShoppingCartAmount(productId, amountField) {
    const identifier = 'product-amount-' + productId;
    if (supports_html5_storage()) {
        const amount = window.localStorage.getItem(identifier);
        if (amount && typeof amount !== 'undefined') {
            amountField.val(amount);
        }

        amountField.on('change', function() {
            const val = $(this).val();
            window.localStorage.setItem(identifier, val);
        });

        amountField.closest('form').on('submit', function() {
            window.localStorage.removeItem(identifier);
        });
    }
}