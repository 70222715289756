"use strict";

var activeMenuItems = [];
function saveMenuState() {
    clearMenuItems();
    $('#side-menu .active').each(function () {
        activeMenuItems.push($(this).data('category'));
    });
}

function restoreMenu() {
    for (var key in activeMenuItems) {
        var categoryId = activeMenuItems[key];
        var elem = $('#side-menu [data-category="' + categoryId + '"]');
        if (elem.length > 0) {
            elem.addClass('active');
        }
    }

    $('#side-menu').metisMenu();
}

function clearMenuItems() {
    activeMenuItems = [];
}

function hasMenuSnippet(evt) {
    if (typeof evt.data.update == 'undefined')
        return false;

    return evt.data.update.hasOwnProperty('snippet--menu');
}

function initMenu(di) {
    var snippetManager = di.getService('snippetManager');
    snippetManager.on('before-update', function (evt) {
        if (hasMenuSnippet(evt)) {
            saveMenuState();
        }
    });

    snippetManager.on('after-update', function(evt) {
        if (hasMenuSnippet(evt)) {
            restoreMenu();
        }
    });
}
